<template>
	<router-view />
	<svws-ui-notification type="error" v-if="!browser()">
		<template #header>
			Browser veraltet
		</template>
		Bitte aktualisieren Sie Ihren Browser, diese Version kann keine Laufbahndaten laden.
	</svws-ui-notification>
	<svws-ui-notifications v-if="errors.length">
		<template v-if="errors.length > 1">
			<svws-ui-button @click="errors = []" type="transparent" class="pointer-events-auto ml-auto rounded-lg bg-white border-light fixed right-6 left-0 top-5 z-50 w-[29rem] max-w-[75vw] justify-center">Alle {{ errors.length }} Meldungen schließen</svws-ui-button>
			<div class="min-h-[1.85rem]" />
		</template>
		<template v-for="error of errors.reverse().slice(0, 20)" :key="error.message">
			<svws-ui-notification type="error">
				<template #header>
					{{ error.name }}
					<template v-if="error instanceof DeveloperNotificationException">
						<br>Programmierfehler: Bitte melden Sie diesen Fehler.
					</template>
					<template v-if="error instanceof UserNotificationException">
						<br>Nutzungsfehler: Dieser Fehler wurde durch eine nicht vorgesehene Nutzung der verwendeten Funktion hervorgerufen, z.B. durch unmögliche Kombinationen etc.
					</template>
				</template>
				{{ error.message }}
				<template #stack v-if="error.stack">
					<pre v-html="error.stack" />
				</template>
			</svws-ui-notification>
		</template>
	</svws-ui-notifications>
</template>

<script setup lang="ts">

	import { DeveloperNotificationException, UserNotificationException } from '@core';
	import { onErrorCaptured, ref } from 'vue';

	const errors = ref<Error[]>([]);
	window.addEventListener("unhandledrejection", function (event) {
		errors.value.push(new Error(event.reason))
		event.preventDefault();
	});

	const browser = () => {
		try {
			const dc = new DecompressionStream("gzip");
			return true;
		} catch (e) {
			return false;
		}
	}

	onErrorCaptured((e) => {
		console.warn(e)
		errors.value.push(e);
		return false;
	});

</script>
